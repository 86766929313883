<template>
  <div v-if="reservation">
    <modal-cancel-reservation
      v-if="showModalCancelReservation"
      :showModal="showModalCancelReservation"
      :onClickCancel="hideModal"
    />

    <modal-change-date
      v-if="showModalChangeDate"
      :showModal="showModalChangeDate"
      :onClickCancel="hideModal"
    />

    <modal-copy-reservation
      v-if="showModalCopyReservation"
      :showModal="showModalCopyReservation"
      :onClickCancel="hideModal"
    />

    <modal-create-invoice
      v-if="showModalCreateInvoice"
      :showModal="showModalCreateInvoice"
      :onClickCancel="hideModal"
    />

    <modal-reservation-set
      v-if="showReservationSet"
      :showModal="showReservationSet"
      :onClickCancel="hideModal"
    />

    <div class="is-hidden-print actionBar has-background-white-ter has-padding">
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <!-- <is-pinned
            :locationId="reservation.LocationId"
            :type="'reservation'"
            :itemId="reservation.Id"
            class="has-icon has-text-link"
          /> -->

          <a @click="downloadReservation" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'file-download']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_Download')
            }}</span>
          </a>

          <a
            @click="setShowCancelReservation"
            class="has-icon has-text-link"
            :class="{ 'has-text-grey': isLocked || reservation.StatusId === 3 }"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'ban']" />
            </span>
            <span>{{
              $t(
                'Components.Reservation.ReservationDetail.Icon_CancelReservation'
              )
            }}</span>
          </a>

          <a
            @click="setShowModalCreateInvoice"
            class="has-icon has-text-link"
            :class="{
              'has-text-grey': reservation.HasInvoice !== 0,
            }"
            ><span class="icon" :class="{ 'is-loading': isCreatingInvoice }">
              <font-awesome-icon :icon="['fas', 'file-invoice']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_CreateInvoice')
            }}</span></a
          >

          <a
            @click="setShowChangeDate"
            :disabled="isSaving"
            class="has-icon has-text-link"
            :class="{ 'has-text-grey': isLocked }"
            ><span class="icon">
              <font-awesome-icon :icon="['fas', 'calendar-alt']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_ChangeDate')
            }}</span></a
          >
          <a
            @click="setShowCopyReservation"
            :disabled="isSaving"
            class="has-icon has-text-link"
            ><span class="icon">
              <font-awesome-icon :icon="['fas', 'copy']" />
            </span>
            <span>{{
              $t(
                'Components.Reservation.ReservationDetail.Icon_CopyReservation'
              )
            }}</span></a
          >
        </div>
      </div>
    </div>

    <section class="section is-small">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-start">
          <span
            v-if="reservation.ReservationSetIds.length > 0"
            class="tag is-link"
            @click="setShowReservationSet"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'clone']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_PartOfSet')
            }}</span>
          </span>

          <span
            v-if="reservation.ReservationSetIds.length === 0"
            class="tag is-disabled"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'clone']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_NotPartOfSet')
            }}</span>
          </span>

          <span
            v-if="reservation.VoucherId > 0"
            class="tag is-primary has-margin-left"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'ticket-alt']" />
            </span>
            <span>{{ reservation.VoucherName }}</span>
          </span>

          <span
            v-if="reservation.VoucherId === 0"
            class="tag is-disabled has-margin-left"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'ticket-alt']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_NoVoucher')
            }}</span>
          </span>

          <span
            v-if="reservation.HasInvoice > 0"
            class="tag is-warning has-margin-left"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'file-invoice']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_InvoiceCreated')
            }}</span>
          </span>

          <span
            v-if="reservation.HasInvoice === 0"
            class="tag is-disabled has-margin-left"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'file-invoice']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationDetail.Icon_NoInvoice')
            }}</span>
          </span>
        </div>
      </nav>

      <accept-reject v-if="reservation.StatusId === 0" class="has-margin-top" />

      <div class="columns has-margin-top">
        <div class="column">
          <reservation-basic :isLocked="isLocked" :isEmployee="isEmployee" />
        </div>
        <div class="column">
          <reservation-invoice :isLocked="isLocked" :isEmployee="isEmployee" />
        </div>
      </div>
    </section>

    <section class="section is-small">
      <ReservationPackage
        v-if="reservation.Voucher && reservation.Voucher.Type === 'Package'"
      />
    </section>

    <section
      class="section is-small"
      v-if="reservation.MeetingtypeId === 1 || reservation.MeetingtypeId === 2"
    >
      <reservation-meetingspaces :isLocked="isLocked" />
    </section>

    <section class="section is-small" v-if="reservation.MeetingtypeId === 3">
      <reservation-deskspaces />
    </section>

    <section class="section is-small">
      <reservation-options :isEmployee="isEmployee" :isLocked="isLocked" />
    </section>

    <section class="section is-small">
      <ReservationTotals />
    </section>

    <section class="section is-small">
      <ReservationTags :isEmployee="isEmployee" :isLocked="isLocked" />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'
import quoteProvider from '@/providers/quote'
import reservationProvider from '@/providers/reservation'

//const IsPinned = () => import('@/components/Pins/IsPinned')
const ModalCancelReservation = () =>
  import('@/components/Reservations/ModalCancelReservation')
const ModalChangeDate = () =>
  import('@/components/Reservations/ModalChangeDate')
const ModalCopyReservation = () =>
  import('@/components/Reservations/ModalCopyReservation')
const ModalCreateInvoice = () =>
  import('@/components/Reservations/ModalCreateInvoice')
const ModalReservationSet = () =>
  import('@/components/Reservations/ModalReservationSet')
const ReservationAcceptReject = () =>
  import('@/components/Reservations/ReservationAcceptReject')
const ReservationDeskspaces = () =>
  import('@/components/Reservations/ReservationDeskspaces')
const ReservationMeetingspaces = () =>
  import('@/components/Reservations/ReservationMeetingspaces')
const ReservationOptions = () =>
  import('@/components/Reservations/ReservationOptions')
const ReservationPackage = () =>
  import('@/components/Reservations/ReservationPackage')
const ReservationTotals = () =>
  import('@/components/Reservations/ReservationTotals')
const ReservationBasicData = () =>
  import('@/components/Reservations/ReservationBasicData')
const ReservationInvoiceAddress = () =>
  import('@/components/Reservations/ReservationInvoiceAddress')
const ReservationTags = () =>
  import('@/components/Reservations/ReservationTags')

export default {
  components: {
    'modal-cancel-reservation': ModalCancelReservation,
    'modal-change-date': ModalChangeDate,
    'modal-copy-reservation': ModalCopyReservation,
    'modal-create-invoice': ModalCreateInvoice,
    'modal-reservation-set': ModalReservationSet,
    'accept-reject': ReservationAcceptReject,
    'reservation-basic': ReservationBasicData,
    'reservation-invoice': ReservationInvoiceAddress,
    'reservation-deskspaces': ReservationDeskspaces,
    'reservation-meetingspaces': ReservationMeetingspaces,
    'reservation-options': ReservationOptions,
    ReservationPackage,
    ReservationTotals,
    ReservationTags,
    //'is-pinned': IsPinned,
  },

  data() {
    return {
      drawerTitle: '',
      isCreatingInvoice: false,
      isEmployee: true,
      isSaving: false,
      itemId: 0,
      locationId: Number(this.$route.params.locationId),
      modalTitle: '',
      reservationId: this.$route.params.reservationId,
      selectedOption: null,
      selectedSpace: null,
      showAddCompany: false,
      showAddProfile: false,
      showCompanySummary: false,
      showModal: false,
      showModalCancelReservation: false,
      showModalChangeDate: false,
      showModalCopyReservation: false,
      showModalCreateInvoice: false,
      showReservationSet: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    isLocked() {
      let self = this
      let locked = false

      if (
        self.isCreatingInvoice ||
        self.reservation.HasInvoice > 0 ||
        self.reservation.StatusId === 3
      ) {
        locked = true
      }

      return locked
    },

    resStart() {
      let minStartMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    spacesTotalSeats() {
      let self = this
      let total = 0

      if (self.reservation && self.reservation !== null) {
        for (var i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          total = total + Number(self.reservation.Spaces[i].Seats)
        }
      }
      return total
    },

    nrOfHours() {
      let hours = 0
      let self = this

      let minutes = self.resEnd - self.resStart
      hours = self.roundToZero(minutes / 60)

      return hours
    },

    nrOfSeats() {
      let seats = 0
      let self = this

      if (self.reservation && self.reservation !== null) {
        seats = Number(this.reservation.TotalSeats)
      }

      return seats
    },
  },

  watch: {
    spacesTotalSeats: function (newValue, oldValue) {
      let self = this

      if (self.reservation && self.reservation !== null) {
        if (self.reservation.TotalSeats === oldValue) {
          self.reservation.TotalSeats = Number(newValue)
        }
      }
    },
  },

  created() {
    EventBus.$on('addToSet', (set) => {
      this.reservation.ReservationSetIds.push(set)
      this.updateReservationStore(this.reservation)
    })
  },

  mounted() {
    let self = this
  },

  beforeDestroy() {
    EventBus.$off('addToSet')
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    getLocationLanguageIds() {
      locationProvider.methods
        .getLocationLanguageIds(this.reservation.LocationId)
        .then((response) => {
          if (response.status === 200) {
            this.languageIds = response.data
          }
        })
    },

    addSpace(space) {
      let self = this

      self.reservation.Spaces.push({
        Id: 0,
        ReservationId: self.reservationId,
        SpaceId: space.SpaceId,
        SpaceName: space.SpaceName,
        SpaceImage: space.SpaceImage,
        SettingId: space.SettingId,
        Seats: space.Seats,
        CurrencyId: space.CurrencyId,
        PricePerSeat: space.PricePerSeat,
        PriceTotal: space.PriceTotal,
        TaxId: space.TaxId,
        TaxPercentage: space.TaxPercentage,
        Crc: space.Crc,
        StartDate: space.StartDate,
        StartMinutes: space.StartMinutes,
        EndDate: space.EndDate,
        EndMinutes: space.EndMinutes,
      })

      let currentSeats = Number(self.reservation.TotalSeats)

      let t = setTimeout(() => {
        self.saveReservation()
        self.hideModal()
        clearTimeout(t)
      }, 0)
    },

    incomingReservationUpdate(incoming) {
      let self = this

      self.reservation.HasDepositInvoice = incoming.HasDepositInvoice
      self.reservation.HasInvoice = incoming.HasInvoice

      // Update store
      self.updateReservationStore(self.reservation)
    },

    saveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .saveReservation(self.reservation, false)
          .then((response) => {
            if (response.status === 200) {
              // Update store
              self.updateReservationStore(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation is saved!',
              })
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              EventBus.$emit('showToast', {
                type: 'warning',
                message: error.response.data.Value,
              })
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    async downloadReservation() {
      reservationProvider.methods
        .getReservationPdf(this.reservationId)
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response.data}`
          const pdfBlob = this.dataURItoBlob(linkSource)

          /**
           * Internet Explorer stuff!
           */
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdfBlob, `${Date.now()}.pdf`)
            return
          }

          const url = window.URL.createObjectURL(pdfBlob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Date.now()}.pdf`)
          document.body.appendChild(link)
          link.click()

          link.remove()
          return response
        })
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const bb = new Blob([ab], { type: mimeString })
      return bb
    },

    cancelReservation(reservation) {
      let self = this
      self.reservation = reservation
      // Update store
      self.updateReservationStore(self.reservation)
    },

    setShowAddDeskspace() {
      this.modalTitle = 'Add deskspace'
      this.setVisibleSideBarItem('addDeskspace')
    },

    setShowModalCreateInvoice() {
      //if (!this.isLocked && this.reservation.StatusId < 3) {
      this.showModalCreateInvoice = true
      //}
    },

    setShowCancelReservation() {
      if (!this.isLocked && this.reservation.StatusId < 3) {
        this.showModalCancelReservation = true
      }
    },

    setShowChangeDate() {
      if (!this.isLocked && this.reservation.StatusId < 3) {
        this.showModalChangeDate = true
      }
    },

    setShowCopyReservation() {
      this.showModalCopyReservation = true
    },

    setShowReservationSet() {
      this.showReservationSet = true
    },

    setVisibleModalItem(item) {
      this.showModal = item.length > 0
    },

    hideModal() {
      this.showModalCancelReservation = false
      this.showModalChangeDate = false
      this.showModalCopyReservation = false
      this.showModalCreateInvoice = false
      this.showReservationSet = false

      this.itemId = 0
      this.drawerTitle = ''

      this.setVisibleModalItem('')
    },

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    goToProposal(quoteId) {
      this.$router.push({
        name: `proposals-detail`,
        params: { proposalId: quoteId },
      })
    },

    createQuote() {
      let self = this
      quoteProvider.methods
        .createQuoteFromReservation(self.reservationId)
        .then((response) => {
          if (response.status === 200) {
            // self.reservation.QuoteId = response.data.Id
            self.reservation.QuoteId = response.data.Id

            // Update store
            self.updateReservationStore(self.reservation)
          }
        })
    },

    /**
     * Update reservation state and original reservation
     */
    updateReservationStore(data) {
      this.setReservation(data)
    },
  },
}
</script>
