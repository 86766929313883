import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async createQuoteFromReservation(reservationId) {
      let criteria = {
        ReservationId: reservationId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/quote/reservation/${reservationId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
